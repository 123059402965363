import React, { useState } from 'react';
import { IndexPageWrapper, SelectPaginate } from '@base-components';
import { ReportLayout } from '../components/report-layout';

import { Card, DatePicker, Form } from 'antd';
import { useFilterDataTable, usePageProviderData } from '@base-hooks';
import { useRecoilValue } from 'recoil';
import { ScopeOptions, ScopeValue } from '../helpers';
import { loginAccountState } from '@base-states';
import { ApiUrlData } from '@base-configs';
import moment from 'moment';
import { makeArrayIds } from '@base-helpers';

export default function PageIndex(): JSX.Element {
  const { authState } = useRecoilValue(loginAccountState);
  const authUser: any = authState.getAccount();

  const scope = authUser.scope;
  const sub_region = authUser?.data_sub_regions ?? [];
  const sub_region_tpi = authUser?.data_sub_regions_tpi ?? [];

  const region_ids = authUser?.data_regions ?? [];
  const sub_region_ids = [...sub_region, ...sub_region_tpi];

  const { moduleKey } = usePageProviderData();
  const [filter, setFilter] = useFilterDataTable(moduleKey);
  const [showContent, setShowContent] = useState(true);

  function reloadContent() {
    setShowContent(false);
    setTimeout(() => {
      setShowContent(true);
    }, 1);
  }

  function makeDefaultScope(scope) {
    if (scope === ScopeValue.global) return scope;
    else if (scope === ScopeValue.region) {
      if (region_ids.length > 0) return scope;
      else if (sub_region_ids?.length > 0) return ScopeValue.sub_region;
    }
    return scope;
  }

  function filterScopeOptions(options, scope) {
    const newScope = makeDefaultScope(scope);
    if (newScope === ScopeValue.global) return options;
    else if (newScope === ScopeValue.region) return options.filter((item) => item.value !== ScopeValue.global);
    else if (newScope === ScopeValue.sub_region) return options.filter((item) => item.value === ScopeValue.sub_region);
  }

  const defaultValueFilter = {
    ...(filter ?? {}),
    date_from: filter?.date_from ?? moment(),
    date_to: filter?.date_to ?? moment(),
    period_type: filter?.period_type ?? 'month',
    scope: filter?.scope ?? makeDefaultScope(scope),
    region_ids: filter?.region_ids ?? region_ids,
    sub_region_ids: filter?.sub_region_ids ?? sub_region_ids,
  };

  return (
    <IndexPageWrapper
      showSearch={false}
      showButtonBack={false}
      // padding={[]}
      showButtonAddNew={scope === 'global'}
      drawerFilterProp={{
        defaultValueFilter,
        onSubmitFilter(value) {
          setFilter(value);
          reloadContent();
        },
        onValuesChange(item, values, form) {
          if (Object.keys(item)[0] === 'scope') {
            form.setFieldsValue({
              ...values,
              region_ids: [],
              sub_region_ids: [],
            });
          }
        },
        formBuilderProps: {
          columns: [
            {
              fields: [
                {
                  name: 'scope',
                  label: 'Scope',
                  fieldType: 'select',
                  gridColumn: { span: 12 },
                  selectProps: {
                    options: filterScopeOptions(ScopeOptions, scope),
                    customLabel(row) {
                      return filterScopeOptions(ScopeOptions, scope).find((item) => item.value === row)?.label;
                    },
                  },
                },

                {
                  gridColumn: { span: 12 },
                  renderField(form) {
                    const key = JSON.stringify([form.getFieldsValue() ?? {}]);
                    const scope = form.getFieldValue('scope');
                    const region = scope === 'region';
                    const sub_region = scope === 'sub_region';
                    if (region) {
                      return (
                        <Form.Item name="region_ids" label="Region">
                          <SelectPaginate
                            key={key}
                            dataSourceUrl={ApiUrlData.region}
                            customLabel={(value) => {
                              return value?.rc_dpw_name;
                            }}
                            filterRequest={{
                              // region_ids: makeArrayIds({ data: region_ids }),
                              status: 'Active',
                              is_strict_region: true,
                            }}
                          />
                        </Form.Item>
                      );
                    }

                    if (sub_region) {
                      return (
                        <Form.Item name="sub_region_ids" label="Church">
                          <SelectPaginate
                            key={key}
                            dataSourceUrl={ApiUrlData.sub_region}
                            customLabel={(value) => {
                              return value?.church_name;
                            }}
                            filterRequest={{
                              status: 'Active',
                              // region_ids: makeArrayIds({ data: region_ids }),
                              // sub_region_ids: makeArrayIds({ data: sub_region_ids }),
                            }}
                          />
                        </Form.Item>
                      );
                    }
                  },
                },

                {
                  name: 'period_type',
                  label: 'Period Type',
                  fieldType: 'radioGroup',
                  gridColumn: { span: 24 },
                  radioGroupProps: {
                    options: [
                      { label: 'Year', value: 'year' },
                      { label: 'Month', value: 'month' },
                      // { label: 'Week', value: 'week' },
                    ],
                  },
                },
                {
                  gridColumn: { span: 12 },
                  renderField(form) {
                    const type = form.getFieldValue('period_type');
                    return (
                      <Form.Item name="date_from" label="Start Date">
                        <DatePicker picker={type} style={{ width: '100%' }} />
                      </Form.Item>
                    );
                  },
                },
                {
                  gridColumn: { span: 12 },
                  renderField(form) {
                    const type = form.getFieldValue('period_type');
                    return (
                      <Form.Item name="date_to" label="End Date">
                        <DatePicker picker={type} style={{ width: '100%' }} />
                      </Form.Item>
                    );
                  },
                },
              ],
            },
          ],
        },
      }}
    >
      <Card className="card-content-wrapper">
        <ReportLayout showContent={showContent} filter={defaultValueFilter} />
      </Card>
    </IndexPageWrapper>
  );
}
