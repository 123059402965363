type AccountEntity = any;
export interface IAuthHelper {
  setAccount(account: AccountEntity): void;
  updateAccount(account: AccountEntity): void;
  getAccount(): AccountEntity;
  clearAccount(): void;
  getAuthToken(): string;
  setWorkflow(payload: any): void;
  getWorkflow(): any;
  setGeneralConfig(payload: any): void;
  getGeneralConfig(): any;
  clearLogout(): void;
}

export class BaseAuthHelper implements IAuthHelper {
  private authKey = 'account';

  // public encode(obj: AccountEntity): string {
  //   if (!obj) return '';
  //   return btoa(JSON.stringify(obj));
  // }
  // public decode(str: string): AccountEntity {
  //   if (!str) return undefined;
  //   return JSON.parse(atob(str));
  // }

  // ✅ Encode dengan TextEncoder agar mendukung Unicode
  public encode(obj: AccountEntity): string {
    if (!obj) return '';
    const encoder = new TextEncoder();
    const uint8Array = encoder.encode(JSON.stringify(obj));
    return btoa(String.fromCharCode(...uint8Array));
  }

  // ✅ Decode dengan TextDecoder agar aman
  public decode(str: string): AccountEntity {
    if (!str) return undefined;
    const uint8Array = Uint8Array.from(atob(str), (c) => c.charCodeAt(0));
    const decoder = new TextDecoder();
    return JSON.parse(decoder.decode(uint8Array));
  }

  setAccount(account: any): void {
    localStorage.setItem(this.authKey, this.encode(account));
  }

  updateAccount(account: any): void {
    const existAccount = this.getAccount();
    Object.assign(existAccount, {
      ...(account ?? {}),
    });
    localStorage.setItem(this.authKey, this.encode(existAccount));
  }

  getAccount() {
    return this.decode(localStorage.getItem(this.authKey));
  }

  clearAccount(): void {
    localStorage.removeItem(this.authKey);
  }

  getAuthToken(): string {
    const account = this.getAccount();
    return account?.access_token;
  }

  setWorkflow(payload: any): void {
    localStorage.setItem('workflow', this.encode(payload));
  }
  getWorkflow(): any {
    return this.decode(localStorage.getItem('workflow'));
  }
  setGeneralConfig(payload: any): void {
    localStorage.setItem('general-config', this.encode(payload));
  }
  getGeneralConfig(): any {
    return this.decode(localStorage.getItem('general-config'));
  }

  clearLogout(): void {
    const removedKey = [this.authKey, 'historyRoute', 'general-config', 'workflow'];
    removedKey.forEach((item) => {
      localStorage.removeItem(item);
    });
  }
}

export const authHelper = new BaseAuthHelper();
